const contestData = {
  '2024년': {
    0: [
      {
        type: '단체',
        subject: '(재)경남테크노파크',
        desc: '경남(창원국가산업단지) 제조산업(기계/항공/방산)의 지속성장 및 고부가가치를 위한 SW융합 생태계를 조성하고, 기업 및 참여대학 지속가능성 확대',
        region: '경남',
        img: require('assets/images/pages/bestPractices/2024/img-contest-1.jpg'),
        alt: '(재)경남테크노파크'
      },
      {
        type: '단체',
        subject: '㈜한국오픈솔루션',
        desc: '외산 SW에 의존* 중인 OPC 엣지게이트웨이 국산화 및 현장 적용을 통해 ‘AI평가 솔루션’을 대기업 생산공정에 적용하여 매출의 안정적 확보를 통한 양질의 지역 일자리 창출에 기여 \r\n * Dell(’17년 기준 국내점유율 54.7%)을 비롯하여 GE, 지멘스, 필립스 등',
        region: '경남',
        img: require('assets/images/pages/bestPractices/2024/img-contest-2.png'),
        alt: '㈜한국오픈솔루션'
      },
      {
        type: '개인',
        subject: '김승모 - (재)포항테크노파크',
        desc: '디지털 인프라 지원 및 기업지원, 비즈니스 모델 발굴이 가능한 플랫폼을 구축하여 경상북도의 디지털 기업 발굴과 디지털 산업발전에 기여함',
        region: '경북',
        img: require('assets/images/pages/bestPractices/2024/img-contest-3.png'),
        alt: '(재)포항테크노파크 김승모 수상자'
      },
      {
        type: '개인',
        subject: '이연제 - (재)대구디지털혁신진흥원',
        desc: '대구-제주지역 초광역 SW융합클러스터를 조성하여 SW융합 신시장 창출 선순환 체계를 구축하고, 글로벌 경쟁력 확보와 중핵 SW산업(AI, 빅데이터, 블록체인) 육성에 기여',
        region: '대구',
        img: require('assets/images/pages/bestPractices/2024/img-contest-4.png'),
        alt: '(재)대구디지털혁신진흥원 이연제 수상자'
      }
    ],
    1: [
      {
        type: '단체',
        subject: '(재)인천테크노파크',
        desc: '균등한 SW교육 제공을 위해 지역 내 도서벽지 및 특수학급(학교) 등 정보소외계층 대상 찾아가는 SW교육을 매년 확대하였고, SW강사의 지속적인 강의 활동 및 자립화를 위해 강사협동조합 설립 및 지원하였으며, SW교육 저변확대 프로그램 등을 통해 SW교육생태계 확산에 기여',
        region: '부산',
        img: require('assets/images/pages/bestPractices/2024/img-contest-5.jpg'),
        alt: '(재)인천테크노파크'
      },
      {
        type: '개인',
        subject: '이한진 - 한동대학교 창의융합교육원',
        desc: '문화예술팩토리 AI·메타버스 전용 교육장 구축을 통한 맞춤형 코딩교육 추진 및 인재양성 콘텐츠·시스템 구축하여 포항시 초중등학생 대상 실감미디어 교육을 추진하고, 해커톤 시상 등 우수성과 확산 노력',
        region: '경북',
        img: require('assets/images/pages/bestPractices/2024/img-contest-6.png'),
        alt: '한동대학교 창의융합교육원 이한진 수상자'
      },
      {
        type: '개인',
        subject: '문은영 - SW미래채움경기센터',
        desc: '2021년부터 특수 청소년(특수학교 및 특수학급 대상)을 위한 소프트웨어 교육을 3년째 진행하며, 특수 청소년, 장애인 성인 및 실버 교육까지 확대하여 디지털 격차 해소에 기여',
        region: '경기',
        img: require('assets/images/pages/bestPractices/2024/img-contest-7.png'),
        alt: 'SW미래채움경기센터 문은영 수상자'
      },
      {
        type: '개인',
        subject: '임준형 - (재)강원정보문화산업진흥원',
        desc: '관광테크 데이터웨어하우스 구축 및 운영을 통해 지역 디지털 생태계 기반 구축에 기여하였으며, 코로나 19로 위축된 해외시장 개척 사업을 담당하여 기업지원 성과도출',
        region: '강원',
        img: require('assets/images/pages/bestPractices/2024/img-contest-8.png'),
        alt: '(재)강원정보문화산업진흥원 임준형 수상자'
      }
    ]
  },
  '2023년': {
    0: [
      {
        type: '단체',
        subject: '㈜캐플릭스',
        desc: '렌터카 실시간 예약서비스 기술을 기반으로 일본시장의 특성에 맞는 백오피스 솔루션을 구현한 차량관리시스템 개발을 통해 일본B2C시장을 개척하는 등 모빌리터업계의 디지털 전환을 확산하여 지역 SW산업 수출 경쟁력 강화 및 신시장 형성에 기여함',
        region: '제주',
        img: require('assets/images/pages/bestPractices/2023/img-contest-1.png'),
        alt: '㈜캐플릭스'
      },
      {
        type: '단체',
        subject: '(재)부산정보산업진흥원',
        desc: '도시 내 설치된 CCTV 영상에 딥러닝 AI 분석기술을 적용하여 관광인프라를 효율적으로 관리하고 범죄, 사고, 재난/재해에 즉시 대응하여 사고 해결과 관련된 사회적 비용을 최소화하며 관광인프라의 효율적 관리로 시민 편의성 제공과 관광객 만족도 향상에 기여함',
        region: '부산',
        img: require('assets/images/pages/bestPractices/2023/img-contest-2.png'),
        alt: '(재)부산정보산업진흥원'
      },
      {
        type: '개인',
        subject: '신성일 - 주식회사 무스마',
        desc: '안전관리로 큰 비용을 들이기 어려운 중·소형 산업현장에 도입하기 쉬운 형태의 산업재해를 방지하기 위한 기술개발을 통해 지역인재 채용 등 양질의 일자리 조성에 기여하며, 산업재해를 IT기술로써 사전에 방지함에 따라 사회적 비용을 절감하는 등 지역발전에 크게 기여함',
        region: '부산',
        img: require('assets/images/pages/bestPractices/2023/img-contest-3.png'),
        alt: '주식회사 무스마 (신성일) 사진'
      },
      {
        type: '개인',
        subject: '정혁 - 대성',
        desc: '정읍 우사 1곳. 완주군 우사 2곳에 악취 저감 시스템을 구축하여 암모니아. 황화수소등의 발생양을 약 50% 저감 시켜 지역사회의 악취 문제 해결 및 대기 환경 개선을 통해 인체 유해물질로부터 시민들을 보호하는 등 지역SW산업에 기여함',
        region: '전북(전주)',
        img: require('assets/images/pages/bestPractices/2023/img-contest-4.png'),
        alt: '대성 (정혁) 사진'
      }
    ],
    1: [
      {
        type: '단체',
        subject: '㈜일주지앤에스',
        desc: '혼합현실 환경에서 로컬 및 원격으로 사용자들의 시각 공유를 통한 교육·훈련·업무 수행으로 사용자가 실제 현장과 융복합된 3D가상 모델의 조작·실습 솔루션 개발을 통해 실제 현장이 빠르게 적용할 수 있도록 하며, 산업 전반에 효율성을 크게 높이며, 지역 일자리 창출과 기업의 역량 강화에 기여함',
        region: '부산',
        img: require('assets/images/pages/bestPractices/2023/img-contest-5.png'),
        alt: '㈜일주지앤에스'
      },
      {
        type: '단체',
        subject: '(재)울산정보산업진흥원',
        desc: 'SW교육기회가 부족한 정보소외지역에 SW교육제공을 통한 SW교육격차 해소 및 미취업 청년, 경력단절 여성 등 구직자에게 SW전문교육강사의 취업 기회를 제공하며, 다양한 지역축제 및 행사와 연계를 통한 SW교육 확산을 통해 지역SW교육지원 강화에 기여함',
        region: '울산',
        img: require('assets/images/pages/bestPractices/2023/img-contest-6.png'),
        alt: '(재)울산정보산업진흥원'
      },
      {
        type: '단체',
        subject: '(재)포항테크노파크',
        desc: '경북 전역 SW교육 기회 제공 및 SW미래채움강사 전문성을 확보했으며, 경북 유일 SW전문인력양성기관으로 지정되어 글로벌로 확산되는 등 지속가능한 SW중심도시 거버넌스 구축에 기여함',
        region: '포항',
        img: require('assets/images/pages/bestPractices/2023/img-contest-7.png'),
        alt: '(재)포항테크노파크'
      },
      {
        type: '개인',
        subject: '최인형 - ㈜펀잇',
        desc: '태국 XR군훈련 시스템 개발로 해외진출하여 신한류 시대의 K-Tech기술을 해외에 보급확산 사업화실적을 이루며, 지역내 우수한 청년 일자리 창출에 기여함',
        region: '전북(전주)',
        img: require('assets/images/pages/bestPractices/2023/img-contest-8.png'),
        alt: '㈜펀잇 최인형 수상자'
      },
      {
        type: '개인',
        subject: '김은선 - SW미래채움경기센터',
        desc: '부천시의 역사, 지역적 특성, 관광지에 대해 조사한 내용 기반으로 시각화되지 않은 것들에 대해 로봇코딩 교육과정을 통해 학생들이 스스로 코딩을 진행하여 개인만의 캐릭터를 꾸미는 등 창의적인 사고를 끌어내며, 지역을 홍보하는 등 지역균형발전에 기여함',
        region: '경기',
        img: require('assets/images/pages/bestPractices/2023/img-contest-9.png'),
        alt: 'SW미래채움경기센터 김은선 수상자'
      },
      {
        type: '개인',
        subject: '김소현 - SW미래채움경기센터',
        desc: 'SW 수업에 접근이 어려운 특수학급 학생들을 위해 효과적인 문제 해결 프로그래밍 체험을 위해 완성형 로봇부터 블록형 로봇까지 단계적인 눈높이 SW교육 프로그램 구성을 포함하여 피지컬 컴퓨팅 교구와 오감을 자극할 수 있는 재료를 사용해 흥미를 유도하는 등 중·장기 프로그램을 제시하여 적극적인 참여를 도와 높은 성취도 달성하며, 단계적 눈높이 SW교육 프로그램 기획지원에 기여함',
        region: '경기',
        img: require('assets/images/pages/bestPractices/2023/img-contest-10.png'),
        alt: 'SW미래채움경기센터 김소현 수상자'
      }
    ]
  },
  '2022년': {
    0: [
      {
        type: '단체',
        subject: '㈜카이아이컴퍼니',
        desc: '코로나19 감염병 사태로 인하여 치과 미내원이 장기화 되면서 치은염 및 치주질환이 심화되는 상황을 해소하기 위해 구강관리를 보조하는 비대면 시스템인 치은염 및 치주 질환 AI진단 데이터를 활요한 맞춤형 구강 모니터링 서비스, 덴티하이를 개발하여 지역 구강건강 증진 및 지역 경제 활성화 기여',
        region: '대전',
        // img: require("assets/images/pages/bestPractices/2022/img-contest-1.png"),
        alt: '㈜카이아이컴퍼니'
      },
      {
        type: '단체',
        subject: '㈜엔틱스',
        desc: '농기계에 부착된 센서(비콘)와 사고감지용 SW(앱)를 통하여 전복·충돌· 도난 사고 감지 및 사고예측을 위한 농기계별 운행데이터 수집 전북SW융합클러스터의 데이터플랫폼에 운행데이터를 연계하여 농기계별 다양한 데이터를 검색 및 공유하고 스마트 농기계 사고관련 기반 기술 활용',
        region: '전북',
        // img: require("assets/images/pages/bestPractices/2022/img-contest-2.png"),
        alt: '㈜엔틱스'
      },
      {
        type: '단체',
        subject: '남서울대학교 산학협력단 SW미래채움충남센터',

        desc: '4차 산업혁명시대에 맞는 충남형 선순환 SW교육 생태계 조성을 위하여 지리적, 경제적 소외계층을 중심으로 SW교육 실시, 그중에서도 SW교육 환경이 열악한 특수학교 학생들을 대상으로 수준별 맞춤 SW교육 기회 제공하여 교육 평등을 통한 사회적 가치 창출',
        region: '충남',
        // img: require("assets/images/pages/bestPractices/2022/img-contest-3.png"),
        alt: '남서울대학교 산학협력단 SW미래채움충남센터'
      },
      {
        type: '개인',
        subject: '박봉배 - 인천테크노파크',
        desc: '4개지역(인천, 부산,전북, 경북) 운여중인 플랫폼에서 제공하는 데이터를 활용한 이종산업간 데이터 결합하는 개방형 기술융합 혁신사업을 통해 신규 비즈니스 모델 실증 및 신기술·신제품 개발, 사업화 연계 등을 지원',
        region: '인천',
        // img: require("assets/images/pages/bestPractices/2022/img-contest-4.png"),
        alt: '인천테크노파크 박봉배 수상자'
      }
    ],
    1: [
      {
        type: '단체',
        subject: '㈜멀틱스',
        desc: '“사람중심의 4차 산업혁명 실현”을 2019년의 과학기술 정책의 기조로 설정하고 시·청각 장애인의 정보 취득의 불균형 및 일상적인 위험을 음성 및 수어 동작인식 등 디지털 기술로 극복할 수 있는 디지털 포용사회 구현하는데 기여함',
        region: '대전',
        // img: require("assets/images/pages/bestPractices/2022/img-contest-5.png"),
        alt: '㈜멀틱스'
      },
      {
        type: '개인',
        subject: '장순필 - ㈜온새미로',
        desc: '시스템반도체의 다품종 소량생산이 가능한 6인치 Vertical Furnace설비를 국산화하고 안정된 Software를 개발,양산함으로써 “2030년 종합반도체 강국” 비전에 원활한 설비공급기반을 마련에 기여함',
        region: '충북',
        // img: require("assets/images/pages/bestPractices/2022/img-contest-6.png"),
        alt: '㈜온새미로 장순필 수상자'
      },
      {
        type: '개인',
        subject: '정소희 - 경남테크노파크',
        desc: '제조업 기업연계 PBL 사업을 진행하여 SW전공 학생들은 실무 현장의 기술 교육 및 채용 기회의 장을 제공, 지역산업기반 SW인력양성을 목표로 지역SW성장 방향성을 제시',
        region: '경남',
        // img: require("assets/images/pages/bestPractices/2022/img-contest-7.png"),
        alt: '경남테크노파크 정소희 수상자'
      },
      {
        type: '개인',
        subject: '김영찬 - (재)전주정보문화산업진흥원',
        desc: '소규모 기업에 적합한 국제표준 기반 SW 개발 프로세스를 개발하였으며, 이를 도입하여 자산관리 체계화, 개발 시스템 구축, 인력 자원 위험을 낮추고, 실무 투입 교육 비용을 최소화하여, 체계적인 고품질 소프트웨어 개발 실현과 품질 경쟁력 강화를 통한 기업 매출 증대, 지속 가능한 비즈니스 구현을 통해 산업 진흥에 이바지',
        region: '전북',
        // img: require("assets/images/pages/bestPractices/2022/img-contest-8.png"),
        alt: '(재)전주정보문화산업진흥원 김영찬 수상자'
      }
    ]
  },
  '2021년': {
    0: [
      {
        type: '단체',
        subject: '㈜YH데이타베이스',
        desc: '노플러그인 환경에서 금융거래 사기 방지를 위한 스마트 금융서비스 구축, Active-X 수집정보가 없는 No plug-in 상태의 금융거래정보만을 이용해서 3년치 금융 거래 빅데이터 사용자별 프로파일링 수행, 금융 거래 정보와 사고정보를 여러 딥러닝 알고리즘을 이용한 최적의 Predict 모델을 ‘자동 도출’하는 솔루션, 1차년도(2020년)시제품 생산, 성공적인 사용화달성, 2차년도(2021년) 출시 제품 사용화 및 매출 확대',
        region: '',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '㈜YH데이타베이스'
      },
      {
        type: '단체',
        subject: '알티앤씨(주)',
        desc: '스쿨존 내 보행중 스마트폰 사용제한 ICT솔루션 개발, KC인증, GS인증 획득으로 품질경쟁력 확보 및 해외 40개국 특허출원, 용인시 관내 초등학교 스쿨존 일대(약4KM)에 테스트베드 구축, 실증, NIPA, 2021년 ICT가 만들어가는 사회적 가치 관제 선정, 조달혁신시제품 지정 인증 획득으로 조달 판로 개척 교두보 마련',
        region: '',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-2.png"),
        alt: '알티앤씨(주)'
      },
      {
        type: '단체',
        subject: '(재)부산정보산업진흥원',
        desc: '시민참여형 바다 SOS-LAB 및 시민연구반 운영을 통한 혁신 시민 양성 및 성과 확산, 사회문제해결형 R&DB지원 및 현장 적용을 통한 지역 사회 문제 해결등 시민 참여형 SOS-LAB 센터 3개소 구축 및 운영(메인/서브/테스트랩), SOS-LAB 운영 및 사회 문제해결형 R&BD과제 지원을 통한 신규고용40명, 지역현안 해결을 위한 시민 연구위원 81명 및 기획연구회의 36회 운영, 지역 커뮤니티 활성화를 위한 퍼실리테이터 양성 교육 5회 운영',
        region: '',
        img: require('assets/images/pages/bestPractices/2023/img-contest-2.png'),
        alt: '(재)부산정보 산업진흥원'
      },
      {
        type: '개인',
        subject: '노의균 - (재)대전정보문화산업진흥원',
        desc: '지역의 생활안전 문제 해결을 위한 대전SOS랩을 통해 시민이 참여하고, 전문가가 협력하고, 기업이 성장하는 생태계를 조성을 위한 시민들이 주체가 되어 생활안전문제를 온라인 플랫폼을 통해 직접 제안하면, 이를 기반으로 RFP를 도출하고 지역기업이 솔루션을 개발하여 해결합니다. 주요성과로 2020년 ‘교통약자를 위한 대전 저상버스 정보공유 시스템 구축 및 실증’과제는 지역균형발전위원장상을 수상, 2019년 ‘사회적 취약계층인 농인의 정보 접근성 보장 기반 구축’과제 행안부 장관상 수상',
        region: '',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-4.png"),
        alt: '(재)대전정보문화산업진흥원 노의균 수상자'
      }
    ],
    1: [
      {
        type: '개인',
        subject: '장현재 - ㈜에프앤디파트너스',
        desc: '“사람중심의 4차 산업혁명 실현”을 2019년의 과학기술 정책의 기조로 설정하고 시·청각 장애인의 정보 취득의 불균형 및 일상적인 위험을 음성 및 수어 동작인식 등 디지털 기술로 극복할 수 있는 디지털 포용사회 구현하는데 기여함',
        region: '',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '㈜에프앤디파트너스 장현재 수상자'
      },
      {
        type: '개인',
        subject: '장태욱 - ㈜퀀텀솔루션',
        desc: '스쿨존 내 보행중 스마트폰 사용제한 ICT솔루션 개발, KC인증, GS인증 획득으로 품질경쟁력 확보 및 해외 40개국 특허출원, 용인시 관내 초등학교 스쿨존 일대(약4KM)에 테스트베드 구축, 실증, NIPA, 2021년 ICT가 만들어가는 사회적 가치 관제 선정, 조달혁신시제품 지정 인증 획득으로 조달 판로 개척 교두보 마련',
        region: '',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-4.png"),
        alt: '㈜퀀텀솔루션 장태욱 수상자'
      },
      {
        type: '개인',
        subject: '한미희 - (재)인천테크노파크',
        desc: '시민참여형 바다 SOS-LAB 및 시민연구반 운영을 통한 혁신 시민 양성 및 성과 확산, 사회문제해결형 R&DB지원 및 현장 적용을 통한 지역 사회 문제 해결등 시민 참여형 SOS-LAB 센터 3개소 구축 및 운영(메인/서브/테스트랩), SOS-LAB 운영 및 사회 문제해결형 R&BD과제 지원을 통한 신규고용40명, 지역현안 해결을 위한 시민 연구위원 81명 및 기획연구회의 36회 운영, 지역 커뮤니티 활성화를 위한 퍼실리테이터 양성 교육 5회 운영',
        region: '',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-6.png"),
        alt: '(재)인천테크노파크 한미희 수상자'
      },
      {
        type: '개인',
        subject: '김진 - (재)경남테크노파크',
        desc: '지역의 생활안전 문제 해결을 위한 대전SOS랩을 통해 시민이 참여하고, 전문가가 협력하고, 기업이 성장하는 생태계를 조성을 위한 시민들이 주체가 되어 생활안전문제를 온라인 플랫폼을 통해 직접 제안하면, 이를 기반으로 RFP를 도출하고 지역기업이 솔루션을 개발하여 해결. 주요성과로 2020년 ‘교통약자를 위한 대전 저상버스 정보공유 시스템 구축 및 실증’과제는 지역균형발전위원장상을 수상, 2019년 ‘사회적 취약계층인 농인의 정보 접근성 보장 기반 구축’과제 행안부 장관상 수상',
        region: '',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-6.png"),
        alt: '(재)경남테크노파크 김진 수상자'
      }
    ]
  }
};
const contestSwData = {
  '2021년': [
    {
      type: '단체',
      subject: 'TMD교육그룹',
      desc: '제주특별자치도는 타 시도대비 상대적으로 SW교육 환경수준이 낮은 지역임. 특히 제주도 내에서도 제주시와 서귀포시의 인프라 및 교육수준의 차이가 크게 나타남. 이에따라 서귀포 지역 중학생을대상으로 SW교육 집중 양성 과정 ‘모델학교 프로그램’을 제공함. 모델학교  프로그램은 ‘기초-기본-심화-고급’ 단계별 과정을 1학기~2학기에 걸쳐 연간 총 50시간 이상 프로그램을 제공함. 현재 서귀포 지역의 총 5개 학교 163명의 청소년들에게 제공하고 있으며, 체계적인 SW교육을 지속적으로 지원하고 있음.',
      region: '',
      // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
      alt: 'TMD교육그룹'
    },
    {
      type: '개인',
      subject: '문명신 - (재)울산정보산업진흥원',
      desc: '경력단절여성으로서 도전하게된 SW미래채움 강사는 처음 접하게 된 소프트웨어 분야이기에 어려움도 많았지만 배움의 과정에서 성취감과 내가 원하는 결과물을 얻는 과정은 인생 2막을 새롭게 시작할 수 있는 큰 원동력이 되었음. SW미래채움 강사로서 새로운 나 자신을 찾게 되었음.',
      region: '',
      // img: require("assets/images/pages/bestPractices/2023/img-contest-4.png"),
      alt: '(재)울산정보산업진흥원 문명신 수상자'
    },
    {
      type: '개인',
      subject: '이정열 - 새미래행복교육사회적협동조합',
      desc: 'SW인공지능 교육 기회가 부족한 지역의 청소년을 대상으로 SW인공지능의 기초역량 강화를 위한 체험과 교육 프로그램을 기획함. 실생활문제를 컴퓨터 소프트웨어와 인공지능 기술을 통해 해결하는 방법과 기초 개념을 재미있는 활동으로 체득할 수 있는 콘텐츠를 개발함. AI체험실 교육 프로그램 운영으로 지역 청소년들의 인공지능에 대한 관심과 탐구 분위기 확산을 도모함.',
      region: '',
      // img: require("assets/images/pages/bestPractices/2023/img-contest-6.png"),
      alt: '새미래행복교육사회적협동조합 이정열 수상자'
    },
    {
      type: '단체',
      subject: '(재)인천테크노파크',
      desc: '1,2,3기 강사 균형 고용 및 팀 제도·TOT 운영을 통한 효율적인 강사관리체계 구축, SW미래채움 강사 협동조합 설립 지원을 통한 2기 강사 협동조합 3개 설립(누적:7개 조합), 인천 SW미래채움 강사 협동조합 자립화를 위한 7개 지원 프로그램 운영, 인천지역 특화 SW체험 콘텐츠로 구성된 인천 SW미래채움 교육 체험관 구축 및 체험단 운영, 인천지역 내 SW교육문화 확산을 위한 ‘인천 SW미래채움 교육페스티벌’ 개최, SW미래채움 1,2기 강사 20명 재능대학교 인공지능컴퓨터정보과 학위 취득 지원, SW미래채움 강사활동 및 자립화 과정 등을 담은 미니 다큐멘터리(3부작) 제작',
      region: '',
      // img: require("assets/images/pages/bestPractices/2023/img-contest-6.png"),
      alt: '(재)인천테크노파크'
    }
  ]
};

const manOfMeritData = {
  '2024년': {
    0: [
      {
        type: '산업계',
        subject: '박민식 상무',
        desc: '선박 항해 시스템 국산화 및 국내 조선 산업 발전에 기여 및 조선/해양 특화 기술과 경험을 지역 업체들과 공유/협업, 조선 분야에 ICT 기술 접목',
        region: '부산',
        affiliation: '주식회사 엠알씨',
        period: '22년 2월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-1.png'),
        alt: '박민식 상무'
      },
      {
        type: '산업계',
        subject: '이건우 대표이사',
        desc: '문화재 분야의 디지털 혁신을 통해 지역SW산업발전과 지역사회 문제점 해결, 지역경제 발전에 기여 및 일자리 13명 창출로 누적 매출 39억 원 달성 및 국내·외 지재권 7건 확보 등',
        region: '경북',
        affiliation: '㈜캐럿펀트',
        period: '6년 3월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-2.jpg'),
        alt: '이건우 대표이사'
      },
      {
        type: '기관',
        subject: '김능대 팀장',
        desc: 'SW미래채움사업을 통해 SW인재양성, 인식개선, 일자리 창출에 기여 및 SW교육 거점센터 구축·운영, 일자리 창출(245명) 및 SW강사 양성을 통해 도내 18개 전지역으로 SW교육 확산하여 SW정보격차 및 교육불균형 해소에 기여, 메타버스와 연계한 SW활용 및 체험 교육 추진',
        region: '강원',
        affiliation: '강릉과학산업진흥원',
        period: '20년 2월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-3.png'),
        alt: '김능대 팀장'
      },
      {
        type: '기관',
        subject: '김성하 팀장',
        desc: '지역SW기업(제품) 신뢰성 강화를 통한 SW산업 경쟁력 강화 지원, 지역SW품질관리 거점기관 구축 ·운영을 통해 품질에 관한 인식개선 및 지역기업의 SW품질경쟁력 향상에 기여, 지역SW진흥기관 최초 SW분야 KOLAS 국제공인시험기관 인정',
        region: '대구',
        affiliation: '(재)대구디지털혁신진흥원',
        period: '9년 2월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-4.jpg'),
        alt: '김성하 팀장'
      },
      {
        type: '기관',
        subject: '최철호 선임연구원',
        desc: '지역 SW산업 발전 및 SW교육 확산에 기여, SW/ICT 기업의 제품 개선 및 고도화를 위한 시제품 제작 지원, 특허분석 등 지원, SW미래채움 사업을 수행하며 초·중등학생 대상 SW교육 제공',
        region: '전북',
        affiliation: '한국전자기술연구원',
        period: '9년 1월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-5.png'),
        alt: '최철호 선임연구원'
      },
      {
        type: '기관',
        subject: '문성원 책임',
        desc: 'AI기반 중량화물이동체 물류플랫폼 실증 사업 수행 및 선박통합데이터센터 건립·운영, ICT융합 Industry4.0 조선해양 사업 수행으로 조선해양 ICT융합센터 건립·운영 및 조선해양 분야 SW품질검증 테스트, 품질평가모델 개발 등 SW품질 경쟁력 강화, 신규사업 기획(ICT 교육센터 설립, 산업 전문인력 양성사업 등)을 통한 지역 ICT융합 활성화에 기여',
        region: '울산',
        affiliation: '울산정보산업진흥원',
        period: '7년 8월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-6.png'),
        alt: '문성원 책임'
      },
      {
        type: '기관',
        subject: '이수정 책임',
        desc: '지역 SW산업 발전과 국내 경쟁력 강화에 기여, SW융합클러스터2.0 및 지역디지털기초체력지원 등 지역SW 사업 수행, 지역 사업 개편방안 마련과 신규사업 기획 및 예산 사업화',
        region: '서울',
        affiliation: '정보통신산업진흥원',
        period: '6년 1월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-7.jpg'),
        alt: '이수정 책임'
      },
      {
        type: '기관',
        subject: '김진 연구원',
        desc: '지역SW산업 발전 및 해외시장 진출을 위해 기여함, 지역 SW기업의 해외 진출을 위한 마케팅 프로그램 기획 및 구성, 해외 유관기관과의 협업 등 지원',
        region: '경남',
        affiliation: '(재)경남테크노파크',
        period: '3년 4월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-8.jpg'),
        alt: '김진 연구원'
      }
    ],
    1: [
      {
        type: '산업계',
        subject: '정경문 팀장',
        desc: "체육 교육의 디지털화, 산업 융합, SW 산업 발전에 기여, 증강현실 실내운동 플랫폼 '디딤'의 콘텐츠 및 시스템 개발, 사용성 확장",
        region: '부산',
        affiliation: '투핸즈인터랙티브',
        period: '3년 4월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-9.png'),
        alt: '정경문 팀장'
      },
      {
        type: '산업계',
        subject: '㈜이젬코 - 단체',
        desc: '화장품 제조 자동화 솔루션 개발로 국내외 경쟁력 강화 및 세종지사 설립과 지역 지원으로 지역 화장품 산업 활성화에 기여',
        region: '서울',
        affiliation: '㈜이젬코',
        period: '11년 2월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-10.png'),
        alt: '㈜이젬코'
      },
      {
        type: '기관',
        subject: '이문목 차장',
        desc: '충남 지역디지털산업 육성을 위한 디지털기업 기술역량 강화 및 디지털산업육성전략수립을 통해 지역디지털산업 활성화에 이바지함',
        region: '충남',
        affiliation: '(재)충남테크노파크',
        period: '23년 4월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-11.jpg'),
        alt: '이문목 차장'
      },
      {
        type: '기관',
        subject: '조정진 팀장',
        desc: '지역SW·디지털 산업 생태계 조성 및 활성화 노력, SW진흥시설 운영 및 안전경영 체계 확립, 입주기업 관리 운영 체계화 마련(현 92개실), 인공지능 학습용 데이터 구축 사업 추진에 따른 크라우드 워커 일자리 창출 554명',
        region: '전북',
        affiliation: '(재)전주정보문화산업진흥원',
        period: '22년 4월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-12.jpg'),
        alt: '조정진 팀장'
      },
      {
        type: '기관',
        subject: '신정연 책임연구원',
        desc: '지역SW산업진흥지원사업 수행을 통한 SW산업 발전 기여, SW전문인력(235명) 양성 및 전문가 위원회 운영 등을 통해 SW융합 신사업 모델 발굴(21건), 국가과제 기획 및 유관기관 네트워크 구축 활동 등의 업무를 수행하며 제주 IT/SW산업 육성에 기여',
        region: '제주',
        affiliation: '제주테크노파크',
        period: '13년 1월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-13.jpg'),
        alt: '신정연 책임연구원'
      },
      {
        type: '기관',
        subject: '김영민 책임',
        desc: '전남 내 SW 인력 양성을 통한 취·창업 역량강화에 기여, 디지털 신기술 교육 109회 및 네트워킹 28회, 교재개발 6건 및 교육환경 구축 4건, 신규창업 14건 및 신규고용 131명 등, KOLAS 운영을 통한 SW기업 품질강화 노력 및 시험성적서 발급 총 10건',
        region: '전남',
        affiliation: '(재)전남정보문화산업진흥원',
        period: '9년 11월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-14.jpg'),
        alt: '김영민 책임'
      },
      {
        type: '기관',
        subject: '김재원 연구원',
        desc: '세종지역 SW산업 육성 및 발전, 일자리 창출에 기여, 4년간(’20~’23년) 지원기업 매출액 615억원 및 일자리 창출 401명 달성으로 지역경제를 활성화하여 지역 내 SW전문인력 628명 양성',
        region: '세종',
        affiliation: '(재)세종테크노파크',
        period: '4년 5월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-15.jpg'),
        alt: '김재원 연구원'
      },
      {
        type: '기관',
        subject: '장민우 대리',
        desc: '데이터 기반 비즈니스 생태계 활성화에 기여, 지역 공공·민간 데이터 교류 및 라이프로그 데이터 구축 및 SW 서비스 상용화 지원',
        region: '인천',
        affiliation: '재단법인인천테크노파크',
        period: '3년 7월',
        img: require('assets/images/pages/bestPractices/2024/img-merit-16.jpg'),
        alt: '장민우 대리'
      }
    ]
  },
  '2023년': {
    0: [
      {
        type: '산업계',
        subject: '장현재 대표',
        desc: '지역SW산업 상용화 지원사업을 통해 “피부질환 전용 의료영상시스템 및 판독 플랫폼 개발‘’의 상용화에 성공하여 분당서울대 등 5개 국립대 병원 납품, 해외진출을 위한 국제 규격 및 인증 확보, 미국 CES에서 22~23년 혁신상 수상 등 SW산업이 정착하지 않은 세종시에서 다양한 R&D프로젝트를 성공적으로 수행하여 지역SW산업 발전에 기여함',
        region: '세종',
        affiliation: '㈜에프앤디파트너스',
        period: '19년 10월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-1.png'),
        alt: '장현재 대표'
      },
      {
        type: '산업계',
        subject: '박윤하 대표이사',
        desc: 'CCTV 영상정보보안 및 분석 분야에 AI기술을 접목한 SW개발을 시작으로 근로자안전관리 솔루션 등 대구경북지역 사회안전에 기여할 수 있는 다양한 솔루션을 개발하였으며 베트남 등 5개 해외 신시장 진출을추진하고, 21~23년 대구 일자리 창출 우수기업 인증 획득 및 22년 청년친화강소기업에 선정되는 등 지역SW산업발전에 기여함',
        region: '대구',
        affiliation: '㈜우경정보기술',
        period: '21년 4월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-2.png'),
        alt: '박윤하 대표이사'
      },
      {
        type: '산업계',
        subject: '추혜성 대표이사',
        desc: '지역 문화재 관리 및 안전사고 예방을 위한 AI모듈을 탑재한 드론시스템을 개발하여 경북 지역 문화재, 노후시설의 재해 위험 사전탐지 등 효율적인 안전관리 시스템을 구축하였으며, ’19년 매출 1.6억원을 시작으로 ’22년 2배 이상의 매출을 달성하는 등 지속적인 매출 증대 및 일자리 창출 추진을 통해 지역SW산업 발전에 기여함',
        region: '경북',
        affiliation: '㈜리하이',
        period: '4년 11월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-3.png'),
        alt: '추혜성 대표이사'
      },
      {
        type: '산업계',
        subject: '이국호 부장',
        desc: '스마트기기의 접근성이 어려운 노인들을 위한 생체정보인식 기술을 활용한 노인돌봄 플랫폼을 개발하여 전남 마을회관 50개소에 시스템 현장 도입을 지원하는 등 전남 지역 노인돌봄 예산절감 지원은 물론 플랫폼 사업화를 통한 매출 증대, 일자리 창출 등 지역SW산업 발전에 기여함',
        region: '전남',
        affiliation: '㈜에스엠소프트',
        period: '23년 6월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-4.png'),
        alt: '이국호 부장'
      },
      {
        type: '산업계',
        subject: '박중건 대표이사',
        desc: '고가의 외산 SW와 차별화된 중소형 조선사를 위한 국산 SW개발 및 드론과 SW기술을 융합한 수상 드론 개발을 통해 대부분 수작업으로 진행되었던 국내 조선산업 분야의 스마트화를 지원하여 1척당 약 3~5%의 예산 절감과 외산 의존도를 감소시킴으로써 지역SW산업 발전에 기여함',
        region: '경남',
        affiliation: '㈜제이디',
        period: '15년 7월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-5.png'),
        alt: '박중건 대표이사'
      },
      {
        type: '산업계',
        subject: '김지성 대표이사',
        desc: '종이문서를 효과적으로 관리하고 활용할 수 있는 비전자문서관리시스템을 개발하여 공공기관, 공사·공단 등에서 보유하고 있는 종이문서를 디지털화 할 수 있도록 지원, 정부의 탄소 중립화에 기여하였으며, 이를 기반으로 한 빅데이터 자동수집 아카이빙관리시스템을 개발 후 GS인증 획득, ‘21년 고양시 우수중소기업에 선정되는 등 지역SW산업 발전에 기여함',
        region: '경기(고양)',
        affiliation: '㈜아키브소프트',
        period: '21년 8월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-6.png'),
        alt: '김지성 대표이사'
      },
      {
        type: '기관',
        subject: '박현옥 수석',
        desc: '지역 내 미래 디지털 인재 양성 기반 마련을 위한 SW·AI교육거점인 SW미래채움사업을 맡아 성실히 수행하고 국민참여예산 확보 노력 등을 통해 지역 내 인력양성 생태계 조성을 위한 사업의 확대와 성과 확산에 기여함',
        region: '서울',
        affiliation: '정보통신산업진흥원',
        period: '20년 4월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-7.png'),
        alt: '박현옥 수석'
      },
      {
        type: '기관',
        subject: '최민희 팀장',
        desc: '경기도 SW미래교육 생태계 조성을 위한 SW미래채움사업의 기획 및 교육 운영지원 등을 통하여 경기도내 인력을 활용한 SW전문강사 양성 및 일자리를 제공하고, 도서벽지학교, 아동센터의 9만 3천여 명의 청소년들에게 SW교육을 추진함에 따라 경기도 내 소외없는 양질의 SW교육 환경을 제공하는데 기여함',
        region: '경기(수원)',
        affiliation: '(재)경기도경제과학진흥원',
        period: '3년 6월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-8.png'),
        alt: '최민희 팀장'
      }
    ],
    1: [
      {
        type: '산업계',
        subject: '정희수 대표이사',
        desc: '국내최초 발신자 검증을 통한 스피어피싱 차단 이메일 보안 솔루션 리얼메일을 통해 스팸메일 차단 기술 개발 및 상용화하여 우수 특허기반 혁신제품으로 선정되는 등 지역SW산업발전에 크게 기여함',
        region: '부산',
        affiliation: '㈜리얼시큐',
        period: '18년 9월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-9.png'),
        alt: '정희수 대표이사'
      },
      {
        type: '산업계',
        subject: '김범기 전략기획이사',
        desc: '생성형 인공지능 기반 선동형 실감 콘텐츠 육성 및 글로벌 메타버스 UGC 생태계 구축을 통해 미래기술 확보에 앞장서며, 디지털 및 소프트웨어산업 발전에 크게 기여함',
        region: '대전',
        affiliation: '㈜카이',
        period: '4년 1개월 ',
        img: require('assets/images/pages/bestPractices/2023/img-merit-10.png'),
        alt: '김범기 전략기획이사'
      },
      {
        type: '산업계',
        subject: '박준석 전무이사',
        desc: '모빌리티 SW분야에서 이동 편의성과 비즈니스 비용 절감을 통해 국민생활 향상과 다수의 국가연구과제를 수행하면서 신기술 개발을 통한 지역의 산업발전과 SW기술선도화 및 개발인력을 적극 양성하면서 지역 우수인력 배출에 크게 기여함',
        region: '경기(용인)',
        affiliation: '㈜아이나비시스템즈',
        period: '21년 6월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-11.png'),
        alt: '박준석 전무이사'
      },
      {
        type: '산업계',
        subject: '이충재 부사장',
        desc: '‘사용 후 배터리 활용 ESS의 PMS Platform’ 개발을 통해 국내최초 임시전력 통력 피크저감용 실증시스템을 구축하여 에너지 효율화 및 소프트웨어산업 발전에 크게 기여함',
        region: '대전',
        affiliation: '㈜대건소프트',
        period: '8년 5월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-12.png'),
        alt: '이충재 부사장'
      },
      {
        type: '기관',
        subject: '박종찬 차장',
        desc: 'ICT이노베이션스퀘어확산사업을 통해 교육수요처 발굴과 강원지역 주력산업과 ICT융합하여 지역산업 경쟁력 확보 및 인력양성 등으로 강원지역 일자리 문제해결에 크게 기여함',
        region: '강원',
        affiliation: '(재)강릉과학산업진흥원',
        period: '11년 4월',
        img: require('assets/images/pages/bestPractices/2023/img-merit-13.png'),
        alt: '박종찬 차장'
      },
      {
        type: '기관',
        subject: '신행화 선임',
        desc: '주민 스스로 사회문제를 제안하는 리빙랩 방식에 대한 인식확산 및 과학기술을 활용한 사회문제 해결 분위기 조성, 주민 참여형 문제발굴 및 솔루현 도출 과정을 통해 지역사회 문제해결 R&D생태계 정착에 이바지하며 ICT/SW산업 성장에 크게 기여함',
        region: '광주',
        affiliation: '(재)전남정보문화산업진흥원',
        period: '4년',
        img: require('assets/images/pages/bestPractices/2023/img-merit-14.png'),
        alt: '신행화 선임'
      },
      {
        type: '기관',
        subject: '임대환 팀장',
        desc: '지역 디지털 혁신 생태계 활성화를 위한 기반 마련에 힘썼으며, 지역주도 SW성장사업 등으로 전북 SW산업 육성 방향 설정 및 신규 사업 유치 등 지역 SW산업 발전 및 성장에 기여함',
        region: '전북(전주)',
        affiliation: '(재)전북테크노파크',
        period: '7년 4월 ',
        img: require('assets/images/pages/bestPractices/2023/img-merit-15.png'),
        alt: '임대환 팀장'
      },
      {
        type: '기관',
        subject: '김기환 차장',
        desc: '지역내 SW산업 혁신자원 집적화를 위한 기반환경을 마련하여 (시민+SW기업+공공기관)이 함께 협력하며 아동이 행복한 인천지역 조성하는 등 SW산업혁신생태계를 구축에 기여함',
        region: '인천',
        affiliation: '인천테크노파크',
        period: '16년 3월 ',
        img: require('assets/images/pages/bestPractices/2023/img-merit-16.png'),
        alt: '김기환 차장'
      }
    ]
  },
  '2022년': {
    0: [
      {
        type: '산업계',
        subject: '김영진 이사',
        desc: '‘전통시장 화재예방을 위한 스마트 화재 감지·제어시스템’고도화 및 확산을 통하여 대전시 고용우수 기업 선정 및 화재 피해금액 점감 등 SW분야 발전에 크게 기여함',
        region: '대전',
        affiliation: '㈜에프에스',
        period: '22년9개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '김영진 이사'
      },
      {
        type: '산업계',
        subject: '용승현 팀장',
        desc: '『인공지능 중심 산업융합 집적단지 조성』 및 지역SW사업(NIPA)에 참여하여 AI특화 창업 및 기업성장 지원에 기여함',
        region: '광주',
        affiliation: '광주정보문화산업진흥원',
        period: '11년8개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '용승현 팀장'
      },
      {
        type: '기관',
        subject: '정재엽 팀장',
        desc: '제주SW진흥지원 사업을 통해 제주지역IT/SW산업 경쟁력 제고와 신규 사업기획, 사업수행 및 유관기관 네트워크 구축등 다양한 업무를 수행하며 제주IT/SW산업 육성에 기여함',
        region: '제주',
        affiliation: '(재)제주테크노파크',
        period: '11년7개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '정재엽 팀장'
      },
      {
        type: '기관',
        subject: '승효진 팀장',
        desc: '지역ICT·SW기업의 성장촉진을 위한(진흥기관 지정, 지역SW상용화지원, 융합인재 양성, 디지털 신기술 활용 지원, 스마트시티산업 성장 촉진 등) 사업을 수행하며 기업의 매출증대, 일자리창출 및 인재양성 등 지역SW산업발전에 기여함',
        region: '세종',
        affiliation: '(재)세종테크노파크',
        period: '12년1개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '승효진 팀장'
      },
      {
        type: '기관',
        subject: '김창우 팀장',
        desc: '부산 센텀시티의 디지털 거점화 및 부산 전통산업의 디지털 전환을 수행하며 지역산업 체질개선 촉진 및 지속 가능한 지역 디지털 산업 활성화 기반 조성에 기여함',
        region: '부산',
        affiliation: '(재)부산정보산업진흥원',
        period: '8년11개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '김창우 팀장'
      },
      {
        type: '기관',
        subject: '임정택 본부장',
        desc: 'SW중심 산업 발전을 위한 업무를 추진해온 바 “SW융합기술고도화사업” 기획팀 참여, “제3정부통합전산센터“기획 PM 등 지역 SW산업 혁신을 위한 대규모 사업을 성공적으로 기획·추진하여 SW중심 지역산업 역량 강화를 위한 기반 마련에 크게 기여함',
        region: '대구',
        affiliation: '(재)대구디지털혁신진흥원',
        period: '14년',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '임정택 본부장'
      },
      {
        type: '기관',
        subject: '임애경 책임',
        desc: '지역 도메인 중심으로 SW·인공지능 분야 기업지원부터 정책전략 수립, 신규사업기획까지 다방명의 분야에서 지역SW산업발전과 국가경쟁력 강화에 기여함',
        region: '충북',
        affiliation: '정보통신산업진흥원',
        period: '5년',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '임애경 책임'
      },
      {
        type: '기관',
        subject: '박진호 부교수',
        desc: '지역SW산업 육성 및 관·산·학 가교 역할 및 대통령 직속 지역발전위, 국회 지역소중포럼 등 지역SW산업발전에 기여함',
        region: '서울',
        affiliation: '동국대학교',
        period: '9년2개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '박진호 부교수'
      }
    ],
    1: [
      {
        type: '산업계',
        subject: '황의철 대표이사',
        desc: '대한민국 최초 드론 활용 물품배송 사업화 및 드론 물류 ICT기술 융합한 드론운송관리시스템 개발을 통해 신시장 창출에 기여함',
        region: '부산',
        affiliation: '㈜해양드론기술',
        period: '4년2개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '황의철 대표이사'
      },
      {
        type: '산업계',
        subject: '최정웅 대표이사',
        desc: 'ICT와 관광, 의료 부분을 융합하여 관광 콘텐츠 정보 제공, VR 어지럼증 개발 등 꾸준한 연구로 지역주민들의 의료 편의를 제공하는 등 SW산업 및 지역SW산업 발전에 기여함',
        region: '강원',
        affiliation: '㈜웰스텍',
        period: '8년7개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '최정웅 대표이사'
      },
      {
        type: '산업계',
        subject: '황큰별 대표이사',
        desc: '지역대학, 전문고등학교와 산학협력을 통해 인재양성 기여기관 및 협회와의 업무협약을 통한 지속적 SW기술 개발 기여 3차원GUI기반 시뮬레이션 및 인공지능 개발 기여',
        region: '전북',
        affiliation: '㈜텔로스',
        period: '7년3개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '황큰별 대표이사'
      },
      {
        type: '산업계',
        subject: '양승해 대표이사',
        desc: '사회적 요구분야 기반 ICT산업발전과 지역경제 발전을 위하여 다양한 분야에서 공하며, R&D사업을 통한 솔루션 개발 및 14건의 특허등록, 16건의 프로그램 등록을 통하여 기술개발 사업화에 기여',
        region: '강원',
        affiliation: '㈜이앤지테크',
        period: '15년',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '양승해 대표이사'
      },
      {
        type: '산업계',
        subject: '이민 이사',
        desc: '지역 일자리 창출 기여 에너지, 드론, 조선, ICT 등 신시장 창출 기여고령화 및 노동력 부족등 지역현안해결 기여함',
        region: '전남',
        affiliation: '㈜에스엠소프트',
        period: '15년2개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '이민 이사'
      },
      {
        type: '기관',
        subject: '윤혜임 선임',
        desc: '지역주도SW성장지원사업 및 클라우드 선도활용 시범지구 조성사업, 지역SW서비스사업화지원사업 등 SW산업 육성 및 기업 지원을 통해 지역의 유망기업 발굴 및 성장 지원, 해외진출 지원 등을 통해 지역기업의 지속 성장 견인 및 지역SW산업 발전에 기여함',
        region: '대구',
        affiliation: '(재)대구디지털혁신진흥원',
        period: '6년7개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '윤혜임 선임'
      },
      {
        type: '기관',
        subject: '현용환 주임',
        desc: '지역SW산업진흥 및 SW서비스 개발사업, SW융합 클러스터사업(1.0) 성과 제고와 대한민국SW융합 해커톤 대회 개최 등을 통해 지역의 SW산업 저변확산과 육성, 일자리 창출등 지역 新성장 산업 진흥에 기여함',
        region: '경북',
        affiliation: '(재)포항테크노파크',
        period: '5년5개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '현용환 주임'
      },
      {
        type: '기관',
        subject: '홍석수 부장',
        desc: '충북 반도체 산업을 중심으로 SW융합생태계 확산에 노력하며 정부의 지역SW사업에 대한 지원 당위성 마련에 기여하고 전문인력 양성 등 지역인재 역량강화에 기여함',
        region: '충북',
        affiliation: '충북과학기술혁신원',
        period: '6년7개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '홍석수 부장'
      }
    ]
  },
  '2021년': {
    0: [
      {
        type: '산업계',
        subject: '오민경 대표',
        desc: '정부의 정책추진 방향과 부산시의 중점시책, 부산시민의 요구사항을 바탕으로 시민참여형 바다 SOS랩 운영 및 SW서비스 개발 사업을 통해 지역사회 문제해결과 시민 중심 혁신문화 창출',
        region: '부산',
        affiliation: '그린업',
        period: '9년11개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '오민경 대표'
      },
      {
        type: '산업계',
        subject: '박호성 부사장',
        desc: 'IT전문인재 양성을 위한 지역고용확대, 4차 산업혁명에 맞춘 솔루션 개발 기술 지역사회 직접개발, 기업부설연구소를 통한 가상현실기술 개발 등 지역의 청년 일자리 창출 및 경제 발전에 기여',
        region: '전남',
        affiliation: '㈜휴먼아이티솔루션',
        period: '18년7개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '박호성 부사장'
      },
      {
        type: '산업계',
        subject: '최종운 대표',
        desc: '지역의 ICT산업 발전을 위해 기업인으로서ICT기반의 문화재/시설물 안전분야, 관광분야 등에서 IoT, SW등의 최신기술 개발과 사업화에 성공하여 강원도 ICT분야 일자리 창출과 고용 등에 기여',
        region: '강원',
        affiliation: '하이테크',
        period: '4년',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '최종운 대표'
      },
      {
        type: '산업계',
        subject: '최재덕 대표이사',
        desc: 'SW연구개발 활동을 통해 국내외 최초로 지역 치안공동체 온라인 협력 플랫폼을 개발하여 조달청 혁신제품으로 지정받고, 혁신조달 우수사례 선정 등 저비용 공공안전 분야 신 시장 창출 및 개적 성공과 지역 경제 활성화에 기여함',
        region: '세종',
        affiliation: '㈜두레윈',
        period: '4년6개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '최재덕 대표이사'
      },
      {
        type: '기관',
        subject: '김민상 책임',
        desc: '인공지능·클라우드·사물인터넷·3D프린팅·블록체인 등 다양한 ICT新기술·서비스의 보급·확산과 함께 의료·조선해양 해운물류 등 전통·특화산업에 ICT·SW융합을 추진하여 지역SW산업발전 및 국가 경쟁력 강화에 기여',
        region: '충북',
        affiliation: '정보통신산업진흥원',
        period: '7년',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '김민상 책임'
      },
      {
        type: '기관',
        subject: '김병철 팀장',
        desc: '친환경·자율운항선박 생태계 구축과 운영을 통한 SW융합 조선해양산업 고도화 선도에 기여하며, AI기반 중량화물이동체 물류플랫폼 실증 추진으로AI를 접목한 해운 물류의 디지털 전환을 통한 조선해운산업의 고도화선도에 기여',
        region: '울산',
        affiliation: '(재)울산정보산업진흥원',
        period: '12년5개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '김병철 팀장'
      },
      {
        type: '기관',
        subject: '주신찬 팀장',
        desc: '부산지역SW산업 진흥 계획 및 발전 전략 수립을 통한 SW산업 활성화에 기여하며 SW관련 신규사업 유치를 통한 他산업과의 융합 및 신시장 발굴에 기여, 부산지역ICT·SW 인력양성, 일자리 창출, 창업 및 네트워크 생태계 조성 기여',
        region: '부산',
        affiliation: '(재)부산정보산업진흥원',
        period: '13년3개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '주신찬 팀장'
      },
      {
        type: '기관',
        subject: '유승두 센터장',
        desc: '지역SW기업성장지원, SW융합클러스터1.0, SW융합클러스터2.0, SW미래채움 사업 기획 및 실무총괄 SOS랩 운영 및 SW서비스 개발 사업 총괄 등 SW저변확대에 기여',
        region: '인천',
        affiliation: '(재)인천테크노파크',
        period: '13년6개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '유승두 센터장'
      }
    ],
    1: [
      {
        type: '산업계',
        subject: '김정수 대표',
        desc: 'IT인재 고용확대 등 부산 경제 활성화에 기여 하고 있으며, 부산시의 ICT 산업정책수행에 있어 다양한 활동들을 적극적으로 지원함으로써 ICT산업 발전에 있어 기여',
        region: '부산',
        affiliation: '㈜에스딘',
        period: '15년',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '김정수 대표'
      },
      {
        type: '산업계',
        subject: '정충교 대표이사',
        desc: '네트워크 솔루션 구축 및 유지·보수 사업을 수행하며, 기업부설 연구소를 세우고 R&D분야 투자를 통해 기술혁 확보 및 지역SW산업 활성화와 지역 고용안정화 등에 기여',
        region: '부산',
        affiliation: '다운정보통신(주)',
        period: '30년6개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '정충교 대표이사'
      },
      {
        type: '산업계',
        subject: '황규범 연구소장',
        desc: '(주)엘라이트 연구 소장직을 성실히 수행하고 있으며 SW의 인력 부족한 충남에서 지역SW산업육성에 기여 및 4차산업에 맞는 고속 검사기에 AI를 접목하여 불량 검출능력과 속도 향상에 이바지하여 지역 일자리 창출 및 신사업 발굴에 기여',
        region: '충남',
        affiliation: '㈜엘라이트',
        period: '4년11개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '황규범 연구소장'
      },
      {
        type: '산업계',
        subject: '전창대 대표이사',
        desc: '지역SW분야 청년창업 스타트업 기업 대표로 2017년부터 현재까지 4차산업 혁명 핵심 기술인 가상증강현실 분야의 기술연구와 서비스 개발을 위해 많은 기여를 하였고 특히 코로나 19 확산에 따른 어려움 속에서도 비대면 온라인 플랫폼을 성공적으로 구축하여 지역 내 중소벤처기업의 판로개척에 큰 기여',
        region: '강원',
        affiliation: '㈜더픽트',
        period: '4년',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '전창대 대표이사'
      },
      {
        type: '기관',
        subject: '김승태 대리',
        desc: '지역SW산업진흥 및 SW융합클러스터 사업의 운영과 성과제고를 통해 지역의 SW산업 저변 확산과 육성 등 경북 지역의 신성장 산업 진흥에 기여',
        region: '경북',
        affiliation: '(재)포항테크노파크',
        period: '7년3개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '김승태 대리'
      },
      {
        type: '기관',
        subject: '배현우 팀장',
        desc: 'SW융합기술고도화 기반 조성사업, 지역SW기업성장지원사업, 지역SW서비스사업화지원사업, SW융합 리딩기업지원프로그램, 혁신성장거버넌스ICT산업분야워킹그룹, 대구디지털혁신전략발표회 등을 성공적으로 기획·운영하였으며, 다양한 기업지원을 수행하며 지역ICT·SW산업 육성에 기여함',
        region: '대구',
        affiliation: '(재)대구디지털산업진흥원',
        period: '6년6개월',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '배현우 팀장'
      },
      {
        type: '기관',
        subject: '윤선희 책임',
        desc: 'SOS랩 구축 및 SW서비스 개발 역량 강화 및 운영 활성화 지원 사업을 통하여‘SOS랩 가이드라인(핸드북)’설계 및 제작, SOS랩 활성화 지원을 위한 역량강화교육 구성 및 운영, 지역별 맞춤형 컨설팅 등 지역 주도 SW·ICT R&D 지속가능 혁신 생태계구축에 기여',
        region: '서울',
        affiliation: '지역SW산업발전협의회',
        period: '4년',
        // img: require("assets/images/pages/bestPractices/2023/img-contest-1.png"),
        alt: '윤선희 책임'
      }
    ]
  }
};

export { contestData, contestSwData, manOfMeritData };

import React from 'react';
import Visual from 'components/common/visual';
import { Box, Container, Tabs, Text, Heading } from '@radix-ui/themes';
import BreadcrumbsBox from 'components/common/breadcrumbsBox/index';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

import './style.scss';

const LocalDigitalInnovation = () => {
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  const [selectedTab, setSelectedTab] = useState('1');

  return (
    <>
      <Visual category="introduction" title="소개" eng="Dreams Introduction" />
      <section>
        <Container>
          <BreadcrumbsBox />
          <PageTitle title="지역 디지털 본부 소개" />
          <Box className="innovation">
            <Box className="explain-box">
              <Box className="title-box">
                <Heading as="h4" className={`title${isMobile ? '5' : '4'}`}>개요</Heading>
              </Box>
              {isMobile ? (
                <Box className="desc-box">
                  <Text as="p" className="body2">
                    지역 디지털 본부는 디지털 신기술을 중심으로 산업･지역 전반의 디지털 융합･혁신 가속화 및 지역 디지털 혁신 정책 전략을
                    지원․수행하고 있습니다. 본부 내에는
                    <strong> 지역디지털기획팀, 지역디지털산업팀, 지역디지털기반팀, 디지털인재양성팀</strong> 총 4개 팀으로 구성되어 있으며,
                    지역 특성을 고려한 디지털 혁신 환경 조성 및 성장 체계를 확립하여 지역 디지털 경쟁력을 강화하고, 글로벌 수준의 지역
                    디지털 생태계를 육성하겠습니다.
                  </Text>
                </Box>
              ) : (
                <Box className="desc-box">
                  <Text as="p" className="body1">
                    지역 디지털 본부는 디지털 신기술을 중심으로 산업･지역 전반의
                  </Text>
                  <Text as="p" className="body1">
                    디지털 융합･혁신 가속화 및 지역 디지털 혁신 정책 전략을 지원․수행하고 있습니다.
                  </Text>
                  <Text as="p" className="body1">
                    본부 내에는
                    <strong> 지역디지털기획팀, 지역디지털산업팀, 지역디지털기반팀, 디지털인재양성팀</strong>총 4개 팀으로 구성되어 있으며,
                  </Text>
                  <Text as="p" className="body1">
                    지역 특성을 고려한 디지털 혁신 환경 조성 및 성장 체계를 확립하여 지역 디지털 경쟁력을 강화하고,
                  </Text>
                  <Text as="p" className="body1">
                    글로벌 수준의 지역 디지털 생태계를 육성하겠습니다.
                  </Text>
                </Box>
              )}
            </Box>
            <Box className="explain-box">
              <Box className="title-box">
                <Heading as='h4' className={`title${isMobile ? '5' : '4'}`}>미션</Heading>
              </Box>
              <Box className="desc-box">
                <p className={`body${isMobile ? '2' : '1'}`}>
                  지역‧산업 분야별 <strong>디지털 융합 혁신 가속화</strong>를 통한{!isMobile && <br />} 지역 디지털 생태계 조성 및 지역 혁신
                  성장 체계 확립
                </p>
              </Box>
              <Box className="image-box">
                <img src={require('assets/images/pages/introduction/img-innovation-mission.svg').default} alt="" />
              </Box>
            </Box>
            <Box className="missions-wrap">
              <div className="title-circle">
                <span className="title title5 slim">
                  <span>
                    지역 디지털
                    <br />
                    <strong>본부</strong>
                  </span>
                </span>
              </div>

              {!isMobile ? (
                <>
                  <div className="trees">
                    <div className="root">
                      <div className="branch"></div>
                    </div>
                    <div className="branches">
                      <div className="branch"></div>
                      <div className="branch"></div>
                      <div className="branch"></div>
                      <div className="branch"></div>
                    </div>
                  </div>
                  <Tabs.Root className="missions" defaultValue="1" onValueChange={setSelectedTab} activationMode="manual">
                    <Tabs.List className="mission-triggers" aria-label="지역 디지털 본부 팀 선택">
                      {[
                        { value: '1', label: '지역 디지털 기획팀' },
                        { value: '2', label: '지역 디지털 산업팀' },
                        { value: '3', label: '지역 디지털 기반팀' },
                        { value: '4', label: '지역 디지털 양성팀' }
                      ].map(({ value, label }) => (
                        <Tabs.Trigger
                          key={value}
                          className="mission-trigger"
                          value={value}
                          aria-selected={selectedTab === value ? '선택됨' : undefined}
                          tabIndex={0}
                        >
                          {label}
                        </Tabs.Trigger>
                      ))}
                    </Tabs.List>
                    <Box className="mission">
                      <Tabs.Content value="1" tabIndex={undefined}>
                        <Box>
                          <p className="title5">지역 디지털 기획팀</p>
                          <Text as="p" mt="4" className="body1">
                            지역 디지털 전략팀은 지역 디지털 역량 강화를 위한 전략과 정책을 수립․지원하고, 지역 디지털 산업 혁신을 견인하기
                            위한 사업을 기획․추진하고 있습니다.
                          </Text>
                          <Text as="p" className="body1">
                            이를 위해 지역 미래 먹거리 창출을 위한 내륙․해운물류, 어장공간정보, 농식품 등 다양한 산업과 디지털을 접목하고,
                            실증․확산 하는 사업을 수행하고 있습니다.
                          </Text>
                        </Box>
                        <Box className="mission-list">
                          <p className="title title6">미션</p>
                          <Box mt="4">
                            <Text as="p" className="body1">
                              지역 디지털 융합‧혁신을 통한 지역 디지털 경쟁력 강화를 위해 관련 정책‧전략 수립 및 신규 사업기획‧지원
                            </Text>
                          </Box>
                        </Box>
                      </Tabs.Content>
                      <Tabs.Content value="2" tabIndex={undefined}>
                        <Box>
                          <p className="title5">지역 디지털 산업팀</p>
                          <Text as="p" mt="4" className="body1">
                            지역 디지털 산업팀은 지역 맞춤형 디지털 융합 新산업 창출·육성 및 지역 디지털 기초체력 강화를 통한 국가균형
                            발전에 기여를 목표로 하고 있습니다.
                          </Text>
                          <Text as="p" className="body1">
                            이를 위해 지역 디지털 기초체력 지원, SW융합클러스터, 제조업 AI융합 기반 조성, AI융합 지능형 농업 생태계 구축 등
                            지역 디지털 산업을 진흥하는 사업을 추진하고 있습니다.
                          </Text>
                        </Box>
                        <Box className="mission-list">
                          <p className="title title6">미션</p>
                          <Box mt="4">
                            <Text as="p" className="body1">
                              지역 디지털 기초체력 강화 및 지역 특화 산업과 디지털 융합 촉진을 위한 사업화, 품질관리, 글로벌 등 新시장진출
                              등 맞춤형 지원
                            </Text>
                          </Box>
                        </Box>
                      </Tabs.Content>
                      <Tabs.Content value="3" tabIndex={undefined}>
                        <Box>
                          <p className="title5">지역 디지털 기반팀</p>
                          <Text as="p" mt="4" className="body1">
                            지역 디지털 기반팀은 지역 디지털 혁신 생태계 조성을 위해 거점 특화 성장과 디지털 신기술 기반을 마련하는 사업을
                            지원합니다.
                          </Text>
                          <Text as="p" className="body1">
                            이를 위해 인공지능 중심 산업융합 집적단지 조성 사업과 디지털혁신거점 조성지원 사업을 수행하고 있습니다.
                          </Text>
                        </Box>
                        <Box className="mission-list">
                          <p className="title title6">미션</p>
                          <Box mt="4">
                            <Text as="p" className="body1">
                              광주 지역 내 AI개발{' '}
                              <Text as="span" className="text-point">
                                핵심자원*
                              </Text>
                              과{' '}
                              <Text as="span" className="text-point">
                                인프라**
                              </Text>
                              를 한 곳에 집약한 생태계 구축을 시작으로 他지역 디지털 혁신거점 확산 지원 추진
                            </Text>
                            <Text as="p" mt="var(--space-6)" className="body1">
                              * 핵심자원 : 데이터‧기술‧인력
                              <br />
                              ** 인프라 : 데이터센터, 실증장비, AI반도체 등
                            </Text>
                          </Box>
                        </Box>
                      </Tabs.Content>
                      <Tabs.Content value="4" tabIndex={undefined}>
                        <Box>
                          <p className="title5">지역 디지털 양성팀</p>
                          <Text as="p" mt="4" className="body1">
                            디지털 인재 양성팀은 국내 산업계에서 요구하는 SW･AI분야 전문인력 양성을 목표로 다양한 형태의 교육 사업을
                            지원하고 있습니다.
                          </Text>
                          <Text as="p" className="body1">
                            이를 위해 서울 및 4개 권역에 ICT이노베이션스퀘어 조성 사업, 산업재직자 AI 역량강화 사업, SW교육 인프라가 부족한
                            지역 초․중등학생 대상 SW교육을 지원하는
                            <br />
                            SW미래채움 사업을 수행하고 있습니다.
                          </Text>
                        </Box>
                        <Box className="mission-list">
                          <p className="title title6">미션</p>
                          <Box mt="4">
                            <Text as="p" className="body1">
                              산업계 요구에 부합하는 맞춤형 디지털 인재 육성·공급과 AI·SW 등 全산업의 디지털 신기술 확산을 위한 인재 양성
                              체계 구축
                            </Text>
                          </Box>
                        </Box>
                      </Tabs.Content>
                    </Box>
                  </Tabs.Root>
                </>
              ) : (
                <Box className="missions">
                  <Box className="mission-trigger">
                    <span className="inner">지역 디지털 기획팀</span>
                  </Box>
                  <Box className="mission">
                    <Box>
                      <Text as="p" className="body2">
                        지역 디지털 전략팀은 지역 디지털 역량 강화를 위한 전략과 정책을 수립․지원하고, 지역 디지털 산업 혁신을 견인하기 위한
                        사업을 기획․추진하고 있습니다.
                      </Text>
                      <Text as="p" mt="var(--space-1)" className="body2">
                        이를 위해 지역 미래 먹거리 창출을 위한 내륙․해운물류, 어장공간정보, 농식품 등 다양한 산업과 디지털을 접목하고,
                        실증․확산 하는 사업을 수행하고 있습니다.
                      </Text>
                    </Box>
                    <Box className="mission-list">
                      <p className="title title7">미션</p>
                      <Box mt="5">
                        <Text as="p" className="body2">
                          지역 디지털 융합‧혁신을 통한 지역 디지털 경쟁력 강화를 위해 관련 정책‧전략 수립 및 신규 사업기획‧지원
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="mission-trigger">
                    <span className="inner">지역 디지털 산업팀</span>
                  </Box>
                  <Box className="mission">
                    <Box>
                      <Text as="p" className="body2">
                        지역 디지털 산업팀은 지역 맞춤형 디지털 융합 新산업 창출·육성 및 지역 디지털 기초체력 강화를 통한 국가균형 발전에
                        기여를 목표로 하고 있습니다.
                      </Text>
                      <Text as="p" mt="var(--space-1)" className="body2">
                        이를 위해 지역 디지털 기초체력 지원, SW융합클러스터, 제조업 AI융합 기반 조성, AI융합 지능형 농업 생태계 구축 등 지역
                        디지털 산업을 진흥하는 사업을 추진하고 있습니다.
                      </Text>
                    </Box>
                    <Box className="mission-list">
                      <p className="title title7">미션</p>
                      <Box mt="5">
                        <Text as="p" className="body2">
                          지역 디지털 기초체력 강화 및 지역 특화 산업과 디지털 융합 촉진을 위한 사업화, 품질관리, 글로벌 등 新시장진출 등
                          맞춤형 지원
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="mission-trigger">
                    <span className="inner">지역 디지털 기반팀</span>
                  </Box>
                  <Box className="mission">
                    <Box>
                      <Text as="p" className="body2">
                        지역 디지털 기반팀은 지역 디지털 혁신 생태계 조성을 위해 거점 특화 성장과 디지털 신기술 기반을 마련하는 사업을
                        지원합니다.
                      </Text>
                      <Text as="p" mt="var(--space-1)" className="body2">
                        이를 위해 인공지능 중심 산업융합 집적단지 조성 사업과 디지털혁신거점 조성지원 사업을 수행하고 있습니다.
                      </Text>
                    </Box>
                    <Box className="mission-list">
                      <p className="title title7">미션</p>
                      <Box mt="5">
                        <Text as="p" className="body2">
                          광주 지역 내 AI개발{' '}
                          <Text as="span" className="text-point">
                            핵심자원*
                          </Text>
                          과{' '}
                          <Text as="span" className="text-point">
                            인프라**
                          </Text>
                          를 한 곳에 집약한 생태계 구축을 시작으로 他지역 디지털 혁신거점 확산 지원 추진
                        </Text>
                        <Text as="p" mt="var(--space-6)" className="body2">
                          * 핵심자원 : 데이터‧기술‧인력
                          <br />
                          ** 인프라 : 데이터센터, 실증장비, AI반도체 등
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="mission-trigger">
                    <span className="inner">디지털 인재 양성팀</span>
                  </Box>
                  <Box className="mission">
                    <Box>
                      <Text as="p" className="body2">
                        디지털 인재 양성팀은 국내 산업계에서 요구하는 SW･AI분야 전문인력 양성을 목표로 다양한 형태의 교육 사업을 지원하고
                        있습니다.
                      </Text>
                      <Text as="p" mt="var(--space-1)" className="body2">
                        이를 위해 서울 및 4개 권역에 ICT이노베이션스퀘어 조성 사업, 산업재직자 AI 역량강화 사업, SW교육 인프라가 부족한 지역
                        초․중등학생 대상 SW교육을 지원하는 SW미래채움 사업을 수행하고 있습니다.
                      </Text>
                    </Box>
                    <Box className="mission-list">
                      <p className="title title7">미션</p>
                      <Box mt="5">
                        <Text as="p" className="body2">
                          산업계 요구에 부합하는 맞춤형 디지털 인재 육성·공급과 AI·SW 등 全산업의 디지털 신기술 확산을 위한 인재 양성 체계
                          구축
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default LocalDigitalInnovation;

import React from 'react';
import Visual from 'components/common/visual';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';
import BreadcrumbsBox from 'components/common/breadcrumbsBox/index';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import { Box, Container, Flex, Heading } from '@radix-ui/themes';

import './style.scss';
import Tap from './Tap';

const PropulsionSystem = () => {
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  return (
    <>
      <Visual category="policy" title="정책" eng="Dreams Policy" />
      <section className="propulsion-system">
        <Container>
          <BreadcrumbsBox />
          <PageTitle title="추진체계" />
          <Box>
            <div className="organization">
              <div className="title-wrap">
                <span className="title title6">
                  과학기술
                  <br />
                  정보통신부
                </span>
              </div>
              <div className="combine">
                <div className="box-wrap">
                  <div className="box blue">
                    <p className={`title${isMobile ? '6' : '5'}`}>정보통신 산업진흥원</p>
                  </div>
                </div>
                {isMobile ? (
                  <>
                    <div className="box-wrap">
                      <div className="box primary">
                        <p className={`title${isMobile ? '6' : '5'}`}>지역 디지털 전략 컨설팅</p>
                      </div>
                    </div>
                    <div className="box-wrap">
                      <div className="box primary">
                        <p className={`title${isMobile ? '6' : '5'}`}>지역 사업 심의위원회</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <Flex gap={isMobile ? '' : '162px'}>
                    <Box className="box-wrap" pt="var(--space-10)">
                      <div className="box primary">
                        <p className={`title${isMobile ? '6' : '5'}`}>지역 디지털 전략 컨설팅</p>
                      </div>
                    </Box>
                    <div className="box-wrap">
                      <div className="box primary">
                        <p className={`title${isMobile ? '6' : '5'}`}>지역 사업 심의위원회</p>
                      </div>
                    </div>
                  </Flex>
                )}

                <div className="box-wrap">
                  <div className="box sky-blue">
                    <p className={`title${isMobile ? '6' : '5'}`}>광역 자치단체 및 지역 SW 산업진흥기관</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="area taps">
              <section className="details">
                <div className="header">
                  <Heading as='h4' className={`title${isMobile ? '5' : '4'}`}>수행주체 별 역할 세부내용</Heading>
                </div>
                <div className="department">
                  <Tap
                    title="과학기술 정보통신부"
                    roleValue="총괄기관"
                    firstSquare="정책수립"
                    firstText="정책 및 사업 기본방향 수립·제시"
                    secondSquare="사업총괄"
                    secondText="사업총괄 및 관리·감독"
                    icon={1}
                  />
                  <Tap
                    title="정보통신 산업진흥원"
                    roleValue="전담기관"
                    firstSquare="사업관리"
                    firstText="사업기획 및 진도 점검·평가, 성과조사 및 분석 등"
                    secondSquare="컨설팅 지원"
                    secondText="지역 사업심의위원회, 지역 디지털 전략 컨설팅 등 컨설팅 지원 체계 구성·운영"
                    icon={2}
                    color="info"
                  />
                  <Tap
                    title="지역 사업 심의위원회"
                    roleValue="사업심의 및 자문"
                    firstSquare="사업심의"
                    firstText="지역별 사업 추진범위, 예산 등 사업계획 검토"
                    secondSquare="사업계획 자문"
                    secondText="기술·시장·정책 관련 자문 지원"
                    icon={3}
                    color="primary"
                  />
                  <Tap
                    title="지역 디지털 전략 컨설팅"
                    roleValue="전략 수립 지원"
                    firstSquare="전략컨설팅"
                    firstText="지역별 중장기 전략수립을 위한 컨설팅"
                    color="primary"
                    icon={4}
                  />
                  <Tap
                    title="지차체"
                    roleValue="사업기획"
                    firstSquare="전략기획 총괄"
                    firstText="지역별 특수성 및 정책 수요를 고려한 지역 전략 및 사업계획 수립 및 제안"
                    icon={5}
                    color="blue"
                  />
                  <Tap
                    title="지역거점 및 지역SW진흥기관"
                    roleValue="사업수행"
                    firstSquare="과제기획"
                    firstText="사업 포트폴리오內, 세부 과제기획·구성"
                    secondSquare="사업수행"
                    secondText="사업 착수·중간·결과보고, 기타 제반 업무 등"
                    icon={6}
                    color="blue"
                  />
                </div>
              </section>
            </section>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default PropulsionSystem;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Box, Text, Flex, Button, TextField, Dialog } from '@radix-ui/themes';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';
import { findUserId, findUserPassword } from 'store/slices/users';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import Visual from 'components/common/visual';
import AuthEmailDialog from 'components/dialog/AuthEmailDialog';

import './style.scss';

const validationEmail = Yup.object({
  email: Yup.string().email('유효하지 않은 이메일 형식입니다.').required('이메일을 입력해주세요.')
});

export default function AuthEmail() {
  const location = useLocation();

  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  const [serverError, setServerError] = useState('');
  const [isOpenCompletePopUp, setIsOpenCompletePopUp] = useState(false);

  // URL path에 따라 비밀번호 찾기 또는 아이디 찾기 구분
  const isPasswordFind = location.pathname === '/findPassword';
  const titleText = isPasswordFind ? '비밀번호 찾기' : '아이디 찾기';
  const submitFunction = isPasswordFind ? findUserPassword : findUserId;

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: validationEmail,
    onSubmit: async e => {
      const result = await submitFunction(e.email);
      if (result.status === 200) {
        setServerError('');
        setIsOpenCompletePopUp(true);
      } else if (result.status === 409) {
        setServerError('등록되지 않은 이메일 주소입니다.');
      }
    }
  });

  // 에러 상태 확인
  const isError = formik.touched.email && formik.errors.email;

  const handleChange = e => {
    formik.handleChange(e);
    if (serverError) {
      setServerError('');
    }
  };

  return (
    <>
      <Visual category="solution" title="솔루션 뱅크" eng="Solution Bank" />
      <section className="login-wrap">
        <Container>
          <BreadcrumbsBox />
          <PageTitle title={titleText} />
          <Text as="p" color="gray" align={isMobile ? 'left' : 'center'} className={isMobile ? 'body2' : 'body1'}>
            아이디를 입력하신 후 인증 메일 받기를 클릭해 주세요. 😀
          </Text>
          <Box mt={isMobile ? 'var(--space-16)' : 'var(--space-25)'}>
            <Flex direction="column" align="center" justify="center">
              <form className="login-box" onSubmit={formik.handleSubmit}>
                <TextField.Root
                  placeholder={isError ? '' : '이메일을 입력해주세요.'}
                  className={isError ? 'error-input' : ''}
                  onChange={handleChange}
                  name="email"
                  value={formik.values.email}
                />
                {/* 에러 메세지 : 유효하지 않은 이메일 입력 시 표시 */}
                {(isError || serverError) && (
                  <Flex align="start" gap="var(--space-1)" className="state-msg">
                    <Box>
                      <img src={require('assets/images/icon/icon-error.svg').default} alt="에러" />
                    </Box>
                    <Text as="span" className="body3 font-error">
                      {isError ? formik.errors.email : serverError}
                    </Text>
                  </Flex>
                )}
                <Button
                  onFocus={() => formik.handleBlur({ target: { name: 'email' } })}
                  size="3"
                  mt="var(--space-6)"
                  className="fluid"
                  type="submit"
                >
                  인증 메일 받기
                </Button>
                {isOpenCompletePopUp && (
                  <Dialog.Root open={isOpenCompletePopUp} onOpenChange={setIsOpenCompletePopUp}>
                    <AuthEmailDialog type={isPasswordFind ? 'password' : 'id'} />
                  </Dialog.Root>
                )}
              </form>
            </Flex>
          </Box>
        </Container>
      </section>
    </>
  );
}

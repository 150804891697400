import React from 'react';
import { Container, Grid, Text } from '@radix-ui/themes';
import Visual from 'components/common/visual';
import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import { CardItem } from 'components/board/boardItem';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

const CardBox = ({ data, i, isMobile }) => (
  <Grid gapX="6" gapY="9" columns={isMobile ? '1' : '4'} mb="6">
    {data[i].map((el, i) => (
      <CardItem key={i} title={el.title} tag={el.tag} subTitle={el.subTitle} index={i} etc={el.etc}>
        <Text as="p" className="body1">
          {el.point && el.desc.includes(el.point)
            ? el.desc.split(el.point).map((txt, i) => (
                <Text key={i}>
                  {txt}
                  {i < el.desc.split(el.point).length - 1 && <strong>{el.point}</strong>}
                </Text>
              ))
            : el.desc}
        </Text>
      </CardItem>
    ))}
  </Grid>
);

export default function BoardCard({ visualProps, pageTitle, data }) {
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';

  return (
    <>
      <Visual category={visualProps.category} title={visualProps.title} eng={visualProps.eng} />
      <section>
        <Container>
          <BreadcrumbsBox />
          <PageTitle title={pageTitle} />
          {/* 카드 목록 */}
          <CardBox data={data} i={0} isMobile={isMobile} />
          <CardBox data={data} i={1} isMobile={isMobile} />
          <CardBox data={data} i={2} isMobile={isMobile} />
        </Container>
      </section>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Flex } from '@radix-ui/themes';
import { yearList } from 'app/constants';
import {
  selectSelectedInfoYear,
  selectSelectedLocalYear,
  selectSelectedAwardYear,
  setSelectedInfoYear,
  setSelectedLocalYear,
  setSelectedAwardYear
} from 'store/slices/common';

import './style.scss';

export default function SortByYear({ path, handleClickYear }) {
  const dispatch = useDispatch();
  const selectedInfoYear = useSelector(selectSelectedInfoYear);
  const selectedLocalYear = useSelector(selectSelectedLocalYear);
  const selectedAwardYear = useSelector(selectSelectedAwardYear);

  const list =
    path === 'award'
      ? yearList.slice(0, -1)
      : path === 'info'
        ? yearList.slice(0, 2)
        : path === 'survey'
          ? yearList.slice(1, 4)
          : yearList.slice(1);

  // console.log('path : ', path);

  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    path === 'award'
      ? setActiveIndex(list.indexOf(selectedAwardYear))
      : path === 'info'
        ? setActiveIndex(list.indexOf(selectedInfoYear))
        : setActiveIndex(list.indexOf(selectedLocalYear));
  }, [path, list, selectedInfoYear, selectedLocalYear, selectedAwardYear]);

  const handleButtonClick = index => {
    setActiveIndex(index);
    path === 'award'
      ? dispatch(setSelectedAwardYear(list[index]))
      : path === 'info'
        ? dispatch(setSelectedInfoYear(list[index]))
        : dispatch(setSelectedLocalYear(list[index]));

    // props로 받은 함수 호출
    if (handleClickYear) {
      handleClickYear(list[index].replace('년', ''));
    }
  };

  return (
    <Flex className="sort-by-year">
      {list.map((item, index) => (
        <Button
          key={index}
          variant="outline"
          size="4"
          color="gray"
          title={activeIndex === index ? `선택됨 ${item}` : ''}
          className={'body1 ' + (activeIndex === index ? 'active' : '')}
          onClick={() => handleButtonClick(index)}
        >
          {item}
        </Button>
      ))}
    </Flex>
  );
}

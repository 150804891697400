import { useEffect } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, Dialog } from '@radix-ui/themes';

import Skipnav from 'components/common/skipnav';
import Header from 'components/layout/header';
import Footer from 'components/layout/footer';
import QuickMenu from 'components/common/quickMenu';
import { ErrorDialog } from 'components/dialog';

import RouteList from 'app/router';
import { instance } from 'api/axios.instance';
import { generateRoutes } from 'app/router';
import { useDevice } from 'assets/js/device';
import { selectIsLogin } from 'store/slices/users';
import { selectIsErrorDialogOpen, setIsErrorDialogOpen, setErrorDialogStatus } from 'store/slices/common';

import '@radix-ui/themes/styles.css';
import 'assets/scss/tokens/df-user/df-user.scss';

export default function App() {
  useDevice();
  const isLogin = useSelector(selectIsLogin);
  const isErrorDialogOpen = useSelector(selectIsErrorDialogOpen);
  const dispatch = useDispatch();
  const location = useLocation();
  const normalizePath = path => path.replace(/\/detail\/\d+$/, '/detail').replace(/\/:\w+/g, '');

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.setAttribute('tabindex', '-1');
      root.focus();
    }
    const currentRoute = RouteList.find(route => normalizePath(route.path) === normalizePath(location.pathname));
    if (currentRoute?.title) {
      document.title = typeof currentRoute.title === 'function' ? currentRoute.title(location.state) : currentRoute.title;
    } else {
      document.title = '내용없음'; // 기본값 설정
    }
  }, [location]);

  useEffect(() => {
    const responseInterceptor = instance.interceptors.response.use(
      response => {
        console.debug(`response => ${response.config.url}`, response);
        return response;
      },
      err => {
        const { response } = err;
        console.debug(`err => ${err}`);
        const { status } = response ?? { status: 500 };

        if (status !== 200) {
          dispatch(setIsErrorDialogOpen(true));
          dispatch(
            setErrorDialogStatus(`[${status}] ${status === 500 ? response.data?.message : response.data?.error || response.data?.message}`)
          );
          setTimeout(() => {
            dispatch(setIsErrorDialogOpen(false));
            dispatch(setErrorDialogStatus(''));
          }, 3000);
        }
        return Promise.reject(response);
      }
    );
    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const currentRoute = RouteList.find(route => route.path === location.pathname);
  const fullScreen = currentRoute?.fullScreen;

  return (
    <div className="App">
      <Theme accentColor={'teal'} radius="none">
        <Skipnav />
        {!fullScreen && <Header />}
        <main id="contents">
          <Routes>{generateRoutes(isLogin)}</Routes>
        </main>
        {!fullScreen && <Footer />}
        <QuickMenu />
        {isErrorDialogOpen && (
          <Dialog.Root open={isErrorDialogOpen} onOpenChange={setIsErrorDialogOpen}>
            <ErrorDialog />
          </Dialog.Root>
        )}
      </Theme>
    </div>
  );
}

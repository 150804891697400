import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import './style.scss';

export default function CustomSelectBox({
  options = [{ value: '' }],
  value,
  onChange,
  placeholder = '선택해주세요.',
  className = '',
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const ref = useRef(null);

  const handleSelect = option => {
    setSelectedValue(option.value);
    onChange(option.value);
    setOpen(false);
  };

  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box className={`custom-select-box ${className}`} ref={ref}>
      <button
        title={selectedValue ? `${selectedValue} 선택됨` : props.title}
        className="select-trigger"
        onClick={() => setOpen(prev => !prev)}
      >
        {selectedValue || placeholder}
        <img
          className={open ? 'up' : 'down'}
          src={require('assets/images/icon/icon-down.svg').default}
          alt={`${open ? '레이어 접기' : '레이어 펼치기'}`}
        />
      </button>
      {open && (
        <Box className="select-popover">
          {options.map((option, index) =>
            // link가 있으면 링크 태그, 없으면 button으로 렌더링
            option.link ? (
              <Link
                onClick={() => setOpen(prev => !prev)}
                key={`${option.value}-${index}`}
                to={option.link}
                title="새창으로 이동"
                target="_blank"
              >
                {option.label}
              </Link>
            ) : (
              <button key={`${option.value}-${index}`} onClick={() => handleSelect(option)}>
                {option.value}
              </button>
            )
          )}
        </Box>
      )}
    </Box>
  );
}

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Box, Button, Flex } from '@radix-ui/themes';
import Visual from 'components/common/visual';
import BreadcrumbsBox from 'components/common/breadcrumbsBox';
import PageTitle from 'components/layout/pageTitle/PageTitle';
import SortByYear from 'components/common/sortByYear';
import { infographicData } from './data';
import { useSelector } from 'react-redux';
import { selectDevice, selectSelectedInfoYear } from 'store/slices/common';
import downloadIcon from 'assets/images/icon/icon-download.svg';

import './style.scss';
import NoData from 'components/common/noData';

export default function PerformanceDetail() {
  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';
  const year = useSelector(selectSelectedInfoYear);

  const { state } = useLocation();

  const handleDownload = async file => {
    try {
      const response = await fetch(file.fileUrl);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file.originalName || 'downloaded-file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Download error:', error);
    }
};

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Visual category="business" pageTitle="사업별 성과 현황" title="사업" eng="Dreams Business" />
      <section className="performance-infographic-wrap">
        <Container>
          <BreadcrumbsBox />
          <PageTitle title={state} />
          {infographicData[year]?.map(
            (el, i) =>
              state === el.title && (
                <Box key={i}>
                  {el.isHistory && <SortByYear path="info" />}
                  {!el.pc && year === '2024년' ? (
                    <>
                      <img
                        key={i}
                        src={isMobile ? infographicData['2023년'][i].mo : infographicData['2023년'][i].pc}
                        alt={infographicData['2023년'][i].alt}
                      />
                      <Button onClick={() => handleDownload(infographicData['2023년'][i])} className="rt-variant-text" pt="var(--space-10)">
                        <img src={downloadIcon} alt={`첨부파일 다운로드`} />
                        <p>{infographicData['2023년'][i].originalName}</p>
                      </Button>
                    </>
                  ) : !el.pc && year === '2023년' ? (
                    <>
                      <img
                        key={i}
                        src={isMobile ? infographicData['2024년'][i].mo : infographicData['2024년'][i].pc}
                        alt={infographicData['2024년'][i].alt}
                      />
                      <Button onClick={() => handleDownload(infographicData['2024년'][i])} className="rt-variant-text" pt="var(--space-10)">
                        <img src={downloadIcon} alt={`첨부파일 다운로드`} />
                        <p>{infographicData['2024년'][i].originalName}</p>
                      </Button>
                    </>
                  ) : (!el.pc && !isMobile) || (!el.mo && isMobile) ? (
                    <Box pt={isMobile ? 'var(--space-6)' : 'var(--space-10)'}>
                      <NoData />
                    </Box>
                  ) : (
                    <>
                      <img key={i} src={isMobile ? el.mo : el.pc} alt={el.alt} />
                      {el.fileUrl && (
                        <Button onClick={() => handleDownload(el)} className="rt-variant-text" pt="var(--space-10)">
                          <img src={downloadIcon} alt={`첨부파일 다운로드`} />
                          <p>{el.originalName}</p>
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              )
          )}
        </Container>
      </section>
    </>
  );
}

import React, { useState } from 'react';
import { Box, Container, Flex, Separator, Dialog, Button } from '@radix-ui/themes';
import TermsDialog from 'components/dialog/TermsDialog';
import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

import './footer.scss';
import CustomSelectBox from 'components/common/customSelectBox';

export default function Footer() {
  // const [temp, setTemp] = useState(0);

  // const handleValueChange = value => {
  //   window.open(value, '_blank');
  //   setTemp(prev => prev + 1);
  // };

  const deviceType = useSelector(selectDevice);
  const isMobile = deviceType === 'mobile';

  return (
    <footer>
      <Container>
        <Flex direction={isMobile && 'column'} align="center" justify="between" py="var(--space-5)">
          <Flex align="center" className="terms-wrap">
            <Box>
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button size="1" type="text" variant="ghost" color="gray" className="body2 font-basic">
                    이용약관
                  </Button>
                </Dialog.Trigger>
                <TermsDialog type="term" title="이용약관" />
              </Dialog.Root>
            </Box>
            <Separator size="1" orientation="vertical" />
            <Box>
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button size="1" type="text" variant="ghost" color="gray" className="body2 font-basic">
                    개인정보처리방침
                  </Button>
                </Dialog.Trigger>
                <TermsDialog type="personal" title="개인정보처리방침" />
              </Dialog.Root>
            </Box>
            <Separator size="1" orientation="vertical" />
            <Box>
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button size="1" type="text" variant="ghost" color="gray" className="body2 font-basic">
                    저작권정책
                  </Button>
                </Dialog.Trigger>
                <TermsDialog type="copyright" title="저작권정책" />
              </Dialog.Root>
            </Box>
            <Separator size="1" orientation="vertical" />
            <Box>
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button size="1" type="text" variant="ghost" color="gray" className="body2 font-basic">
                    이메일무단수집거부
                  </Button>
                </Dialog.Trigger>
                <TermsDialog type="email" title="이메일무단수집거부" />
              </Dialog.Root>
            </Box>
          </Flex>
          {/* TODO 하단 셀렉트를 컴포넌트로 만들었으면 합니다. 게시판 상단 검색영역 셀렉트에도 적용 필요 */}
          <CustomSelectBox
            title="패밀리 사이트 구분 선택"
            className="family-site"
            placeholder="Family Site"
            options={[
              { label: '과학기술 정보통신부', link: 'https://www.msit.go.kr/' },
              { label: '정보통신 산업진흥원', link: 'https://www.nipa.kr/' }
            ]}
          />
        </Flex>
        <Flex className="info-wrap">
          <span className="logo">DREAMS</span>
          <Box className="info">
            <p className="sub-title1">지역SW산업발전협의회</p>
            <ul>
              <li className="body2 address">주소 : 서울특별시 마포구 월드컵북로 396, 14층 상암동 누리꿈 스퀘어 연구개발타워</li>
            </ul>
            <p className="copyright">Copyright © 2024 RSW All Right Reserved.</p>
          </Box>
        </Flex>
      </Container>
    </footer>
  );
}

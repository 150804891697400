import React from 'react';

import './style.scss';

export default function Skipnav() {
  const handleSkip = id => event => {
    event.preventDefault();
    const target = document.getElementById(id);
    if (target) {
      const firstFocusable = target.querySelector('a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])');
      if (firstFocusable) {
        firstFocusable.focus();
        return;
      }
    }
  };

  return (
    <>
      <div id="skipnav">
        <a href="#contents" onClick={handleSkip('contents')}>
          본문 바로가기
        </a>
        <a href="#menu" onClick={handleSkip('menu')}>
          주메뉴 바로가기
        </a>
      </div>
    </>
  );
}
